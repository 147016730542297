import { Injectable } from '@angular/core';
import { Camera, GalleryImageOptions } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { LoadingController } from '@ionic/angular';
import { Observable, map, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Document, Media, MediaType } from '../models/media';
import { DocumentUtils } from '../utils/document.utils';
import { ApiService } from './api.service';

export type TagDefinition = {
    value?: string;
    name: string;
    filterBased: boolean;
    type?: 'number' | 'boolean';
};

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    private medias: { [key: string]: Media } = {};
    static readonly mediaTagDefinitions: { [key: string]: TagDefinition[] } = {
        real_estate: [
            { name: _('outside'), filterBased: false },
            { name: _('living_room'), filterBased: true, type: 'number' },
            { name: _('dining_room'), filterBased: true, type: 'number' },
            { name: _('kitchen'), filterBased: true, type: 'number' },
            { name: _('bathroom'), filterBased: true, type: 'number' },
            { name: _('bedroom'), filterBased: true, type: 'number' },
            { name: _('swimming_pool'), filterBased: false }, // Prévoir un type "texte"
            { name: _('garden'), filterBased: false },
            { name: _('garage'), filterBased: false },
            { name: _('terrace'), filterBased: false },
            { name: _('balcony'), filterBased: false },
            { name: _('entrance'), filterBased: false },
            { name: _('corridor'), filterBased: false },
            { name: _('walking_closet'), filterBased: true, type: 'number' },
            { name: _('attached_bathroom'), filterBased: true, type: 'number' },
            { name: _('other'), filterBased: false },
        ],
        vehicles: [
            { name: _('outside'), filterBased: false },
            { name: _('inside'), filterBased: false },
            { name: _('trunk'), filterBased: false },
            { name: _('dashboard'), filterBased: false },
            { name: _('wheels'), filterBased: false },
            { name: _('other'), filterBased: false },
        ],
        bar_restaurant: [
            { name: _('outside'), filterBased: false },
            { name: _('inside'), filterBased: false },
            { name: _('meal'), filterBased: false },
            { name: _('terrace'), filterBased: true, type: 'boolean' },
            { name: _('kid_area'), filterBased: true, type: 'boolean' },
        ],
    };

    constructor(
        private readonly apiService: ApiService,
        private readonly loadingCtrl: LoadingController,
    ) {}

    upload(
        blob: Blob,
        filename: string,
        type: MediaType,
        progress: (progress: number) => void,
        done: (media: Media) => void,
        mediaTag: string = null,
    ): void {
        // Upload file as FormData object
        const formData = new FormData();
        formData.append('file', blob, filename);
        formData.append('type', type || 'public');
        if (mediaTag) {
            formData.append('tag', mediaTag);
        }

        this.apiService.upload('/api/medias', formData, true, progress, done);
    }

    pickImage(options?: GalleryImageOptions): Promise<{ blob: Blob; filename: string }> {
        // If input "_capacitor-camera-input-multiple" is present, remove it
        const input = document.getElementById('_capacitor-camera-input-multiple');
        if (input) {
            input.remove();
        }

        return new Promise((resolve, reject) => {
            options = options || { quality: 100, limit: 1 };
            Camera.pickImages(options)
                .then(async (result) => {
                    if (result.photos.length >= 1) {
                        const webPath = result.photos[0].webPath;
                        // Create a File object from webPath
                        const fetchResponse = await fetch(webPath);
                        const blob = await fetchResponse.blob();
                        const filename = result.photos[0].webPath.split('/').pop();

                        resolve({ blob, filename });
                    } else {
                        reject({ code: 'no_image_selected', message: 'No image selected' });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    get(mediaId: number): Observable<Media> {
        // We cache the medias in a private variable
        if (this.medias['' + mediaId]) {
            return new Observable((observer) => {
                observer.next(this.medias[mediaId]);
                observer.complete();
            });
        }
        return this.apiService.get<Media>(`/api/medias/${mediaId}`).pipe(tap((m) => (this.medias['' + m.id] = m)));
    }

    resolveMedia(path: string): string {
        return environment.FIREBASE_STORAGE_URL + path;
    }

    getDocuments(companyId: number) {
        return this.apiService
            .get<Document[]>(`/api/companies/${companyId}/documents`)
            .pipe(map((documents: Document[]) => documents.map((d) => new Document(d))));
    }

    createDownload(id: number) {
        return this.apiService.post<{ url: string }>(`/api/medias/${id}/download`, {});
    }

    pickDocument(type: MediaType) {
        return new Promise<Media>(async (resolve) => {
            const result = await FilePicker.pickFiles({
                types: ['application/pdf'],
                multiple: false,
                readData: true,
            });

            // Check if we have a result
            if (!result || !result.files || !result.files.length) {
                return resolve(null);
            }

            const loader = await this.loadingCtrl.create();
            loader.present();

            // Use blob if available
            const file = result.files[0];
            let blob = null;
            if (file.blob) {
                blob = file.blob;
            } else {
                // Convert file.data (base64) to blob
                blob = DocumentUtils.b64toBlob(file.data, 'application/pdf');
            }

            // Upload media
            return this.upload(blob, file.name, type, null, (media) => {
                resolve(media);
            });
        });
    }
}
